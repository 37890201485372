// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bn_kC";
export var caseStudyBackground__bgColor = "bn_ky";
export var caseStudyBackground__lineColor = "bn_kz";
export var caseStudyCta__bgColor = "bn_kP";
export var caseStudyHead__imageWrapper = "bn_kv";
export var caseStudyProjectsSection = "bn_kD";
export var caseStudyQuote__bgLight = "bn_kK";
export var caseStudyQuote__bgRing = "bn_kx";
export var caseStudyTechSection = "bn_kB";
export var caseStudyVideo = "bn_kM";
export var caseStudyVideo__ring = "bn_kN";
export var caseStudy__bgDark = "bn_ks";
export var caseStudy__bgLight = "bn_kr";